/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {


  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/beetle.glb')
  const { actions } = useAnimations(animations, group)

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Armature" position={[0.31, -0.13, -0.33]} rotation={[0, 0.06, 0.02]} scale={[0.02, 0.02, 0.02]}>
        <primitive object={nodes.root} />
        <primitive object={nodes.IKtargetL} />
        <primitive object={nodes.arm1upL005} />
        <primitive object={nodes.IKtarget2L} />
        <primitive object={nodes.IKpole2L} />
        <primitive object={nodes.IKtarget3L} />
        <primitive object={nodes.IKTarget3L} />
        <primitive object={nodes.IKTargetM} />
        <primitive object={nodes.IKtargetR} />
        <primitive object={nodes.arm1upR005} />
        <primitive object={nodes.IKtarget2R} />
        <primitive object={nodes.IKpole2R} />
        <primitive object={nodes.IKtarget3R} />
        <primitive object={nodes.IKTarget3R} />
        <skinnedMesh
          geometry={nodes.Cube001.geometry}
          material={materials['Material.005']}
          skeleton={nodes.Cube001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube001_1.geometry}
          material={materials['Material.033']}
          skeleton={nodes.Cube001_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube001_2.geometry}
          material={materials['Material.046']}
          skeleton={nodes.Cube001_2.skeleton}
        />
      </group>
    </group>
    
  )
}

useGLTF.preload('/beetle.glb')
