/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/forest6.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}  position={[0,-1.3,0]}>
      <group name="Sphere027" rotation={[3.12, -0.3, -0.03]} scale={[2.27, 0.99, 2.27]}>
        <mesh geometry={nodes.Sphere028.geometry} material={materials.forestground} />
        <mesh geometry={nodes.Sphere028_1.geometry} material={materials.stoneforest} />
        <mesh geometry={nodes.Sphere028_2.geometry} material={materials['Material.027']} />
        <mesh geometry={nodes.Sphere028_3.geometry} material={materials.steen} />
      </group>
    </group>
  )
}

useGLTF.preload('/forest6.glb')
