/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations, softShadows } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/beetlenft.glb')

  softShadows({
    frustum: 3.1,
    size: 0.003,
    near: 6.1,
    samples: 100,
    rings: 11,
  });

  return (
    <group ref={group} {...props} dispose={null} scale = {0.4} position={[0,-1,1]}>
      <group name="Armature" rotation={[0.06, 0, 0]}>
        <primitive object={nodes.root} />
        <primitive object={nodes.IKtargetL} />
        <primitive object={nodes.arm1upL005} />
        <primitive object={nodes.IKtarget2L} />
        <primitive object={nodes.IKpole2L} />
        <primitive object={nodes.IKtarget3L} />
        <primitive object={nodes.IKTarget3L} />
        <primitive object={nodes.IKTargetM} />
        <primitive object={nodes.IKtargetR} />
        <primitive object={nodes.arm1upR005} />
        <primitive object={nodes.IKtarget2R} />
        <primitive object={nodes.IKpole2R} />
        <primitive object={nodes.IKtarget3R} />
        <primitive object={nodes.IKTarget3R} />
        <skinnedMesh castShadow
          geometry={nodes.Cube002.geometry} 
          material={materials['6']} //body//
          skeleton={nodes.Cube002.skeleton} />

        <skinnedMesh castShadow
          geometry={nodes.Cube002_1.geometry}
          material={materials['7']} //legs//
          skeleton={nodes.Cube002_1.skeleton}
        />
        <skinnedMesh castShadow
          geometry={nodes.Cube002_2.geometry}
          material={materials['8']} //eyes&antenas//
          skeleton={nodes.Cube002_2.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_3.geometry}
          material={materials['4']}
          skeleton={nodes.Cube002_3.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_4.geometry}
          material={materials['5']}
          skeleton={nodes.Cube002_4.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_5.geometry}
          material={materials['6']}
          skeleton={nodes.Cube002_5.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_6.geometry}
          material={materials['7']}
          skeleton={nodes.Cube002_6.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_7.geometry}
          material={materials['8']}
          skeleton={nodes.Cube002_7.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_8.geometry}
          material={materials['9']}
          skeleton={nodes.Cube002_8.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_9.geometry}
          material={materials['10']}
          skeleton={nodes.Cube002_9.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_10.geometry}
          material={materials['11']}
          skeleton={nodes.Cube002_10.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_11.geometry}
          material={materials['12']}
          skeleton={nodes.Cube002_11.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_12.geometry}
          material={materials['13']}
          skeleton={nodes.Cube002_12.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_13.geometry}
          material={materials['14']}
          skeleton={nodes.Cube002_13.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube002_14.geometry}
          material={materials['15']}
          skeleton={nodes.Cube002_14.skeleton}
        />

        <directionalLight
              castShadow
              position={[10, 10, 0]}
              intensity={6}
              shadow-mapSize-Width={1024}
              shadow-mapSize-Height={1024}
              shadow-camera-far={50}
              shadow-camera-left={-15}
              shadow-camera-right={10}
              shadow-camera-top={15}
              shadow-camera-bottom={-15}
        />
      </group>
        <mesh
          receiveShadow
          rotation={[-Math.PI / 2, 0, 1]}
          position={[0, 0, 0]}    
        >
          <planeBufferGeometry args={[200, 200]} />
          <shadowMaterial attach='material' opacity={0.3} />
        </mesh>
    </group>
  )
}

useGLTF.preload('/beetlenft.glb')
