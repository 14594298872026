/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/forest3.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sphere004" rotation={[-0.08, 0, 0]} scale={[2.27, 0.99, 2.27]}>
        <mesh geometry={nodes.Sphere005.geometry} material={materials.forestground} />
        <mesh geometry={nodes.Sphere005_1.geometry} material={materials.steen} />
        <mesh geometry={nodes.Sphere005_2.geometry} material={materials['Material.027']} />
        <mesh geometry={nodes.Sphere005_3.geometry} material={materials.stoneforest} />
        <mesh geometry={nodes.Sphere005_4.geometry} material={materials.mossforest} />
      </group>
    </group>
  )
}

useGLTF.preload('/forest3.glb')
