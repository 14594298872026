import './Home.css';
import React, {Suspense, useState, useRef, useEffect} from 'react';
import ExampleCanvas from '../../Secondary/Functional/ExampleCanvas.js'
import { Parallax } from 'react-scroll-parallax';
import Desert1 from '../../Secondary/GLB/Desert1.js';
import Desert2 from '../../Secondary/GLB/Desert2.js';
import Desert6 from '../../Secondary/GLB/Desert6.js';
import Forest1 from '../../Secondary/GLB/Forest1.js';
import Forest2 from '../../Secondary/GLB/Forest2.js';
import Sakura1 from '../../Secondary/GLB/Sakura1.js';
import Sakura2 from '../../Secondary/GLB/Sakura2.js';
import Desertstone1 from '../../Secondary/GLB/Desertstone1.js';
import LogPusher2 from '../../Secondary/GLB/LogPusher2.js'
import Puzzlecounter from '../../Secondary/GLB/Puzzlecounter.js';
import Beetle from '../../Secondary/GLB/Beetle.js';
import ExampleBeetle from '../../Secondary/Functional/ExampleBeetle.js'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Canvas} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';
import { motion, AnimatePresence} from "framer-motion"
import FadeInWhenVisible from '../../Secondary/Functional/FadeInWhenVisible.js';
import CustomLoader from '../../Secondary/Functional/CustomLoader.js'
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as DiscordIcon } from '../../NonPublicImages/discord-logo.svg';

export default function Body(){

    const [carouselCounter, setCarouselCounter] = useState(0)
    const [parallax, setParallax] = useState([-20, 150])
    const [mobile,setMobile] = useState(false)
    const [page,setPage] = useState('home')

    useEffect(() => {
        if(window.innerWidth < 800){
            setParallax([0,0])
            setMobile(true)
        }
    },[])
    

    const handleCarouselRight = () => {
        console.log(carouselCounter)
        if(carouselCounter < 6){
            setCarouselCounter(carouselCounter + 1)
        }
        if(carouselCounter > 5){
            setCarouselCounter(0)
        }
    }
    const handleCarouselLeft = () => {
        console.log(carouselCounter)
        if(carouselCounter > 0){
            setCarouselCounter(carouselCounter -1)
        }
        if(carouselCounter < 1){
            setCarouselCounter(6)
        }
    }





    function CanvasItems(){
        if(carouselCounter === 0){
            return(
            <>
            <Desert1 position = {[0,-1.5,0]}/>
            </>
            )
        }
        if(carouselCounter === 1){
            return( 
                <>
                <Forest1 position = {[0,-2,0]}/>
                </>
            )
        }
        if(carouselCounter === 2){
            return( <>
                    <Beetle scale={[1.5,1,1.5]} position ={[-0.2,0,0.2]}/>   
                    <Sakura2 position = {[0,0,0]}/>
                    
                    </> 
                )
        }
       
        if(carouselCounter === 3){
            return  <Desert2  position = {[0,-1.5,0]}/>
           
        }
        if(carouselCounter === 6){
            return  <Sakura1 position = {[0,0,0]} scale = {0.8}/>
        }
       
        if(carouselCounter === 5){
            return(<>
            <Forest2 position = {[0,-1,0]} />
            </>)  
        }
        if(carouselCounter === 4){
            return(
            <>
            <Desert6 position = {[0,-1.5,0]}/>
            <Desertstone1/>
            </>
            )
        }
    }



                

    function Left(){
           return <ChevronLeftIcon className = 'chevron left' onClick={handleCarouselLeft}/>
    }
    function Right(){
            return <ChevronRightIcon className = 'chevron right' onClick={handleCarouselRight}/>  
    }

    const [button, setButton] = useState('start voyage')
    
    function startVoyage(){
        setButton('coming soon')
    }

    if(page === 'home'){

    return(
    <AnimatePresence>
    
    <img className='cardano-logo' style={{height: '1.2em', width: 'auto', margin: '0 0.1em', position: 'fixed', bottom: '2em', right: '2em'}} src="/cardano-logo.png" alt="" />
    <div className="header-icons-container">
        <h2 className="nav-link"  onClick={()=> setPage('home')} style={{marginRight: '1em'}}>Islands</h2>
        <h2 className="nav-link" onClick={()=> setPage('creatures')}>Wildlife</h2>
        <h2 className="nav-link" onClick={()=> setPage('artifacts')} style={{marginLeft: '1em'}}>Artifacts</h2>
        <h2 className="nav-link" onClick={()=> setPage('faq')} style={{marginLeft: '1em'}}>FAQ</h2>
        <a href='https://discord.gg/mQ2KAVNU9v' target = '_blank' >
            <DiscordIcon className = 'footer-image discord-logo'/>
        </a>
        <a href = 'https://twitter.com/teamgetaways' target = '_blank'>
            <TwitterIcon className = 'footer-image twitter-logo' />
        </a>
    </div>
   
    
    <motion.div 
        className = 'canvas-carousel-container'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
            duration: 3
        }}
        >
        
        <Parallax y={[0,20]}>
            <div className = "quote-container" >
                <motion.p
                       
                       initial={{ opacity: 0 }}
                       animate={{ opacity: 1 }}
                       exit={{ opacity: 0 }}
                       transition={{
                           duration: 3
                       }}
                       > " The real voyage of discovery consists not in seeking new landscapes, but in having new eyes. "
                   </motion.p>
            </div>
        </Parallax>

   
        
            
    
            <div className = 'canvas-container' >
                
                
                <Left/>
                <Canvas
                    camera = {{
                        position: [10, 5, 29],
                        fov: 24
                    }}
                 >
                    <Suspense fallback = {<CustomLoader color="white" />}>
                    <group position={[4.05, 6.31, -1.05]} rotation={[1.89, 0.88, -2.05]}>
                        <spotLight intensity={1} angle={1} penumbra={1} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
                    </group>
                      
                        <OrbitControls enableZoom = {false} enablePan={false}/>
                        <directionalLight position = {[-4,1,4]} intensity = {0.4}/> 
                        <CanvasItems/>
                    </Suspense>
                </Canvas>
                <Right/>
                
         
        </div>

  


    
    </motion.div>

    


    <Parallax y={parallax}> 

        
            <div className = "poem-container" style = {{padding:'0'}}>
            <FadeInWhenVisible>
                <p>Puzzels to be solved...</p>
                </FadeInWhenVisible>  
            <FadeInWhenVisible> 
                <p> Wild life to be discovered... </p>
                </FadeInWhenVisible>   
            <FadeInWhenVisible>    
                <p>We'll have plenty of time together, don't worry.</p>
                </FadeInWhenVisible>   
            <FadeInWhenVisible>    
                <p>Time to take it slow.</p>
                </FadeInWhenVisible>        
            </div>
       
    
    </Parallax>
  
    
    <div className = "examples-grid-container">
    
        <ExampleCanvas glbComponentName='Forest3' />
        <ExampleCanvas glbComponentName='Desert5' />
        <ExampleCanvas glbComponentName='Desert3' />
        <ExampleCanvas glbComponentName='Desert4' />
        <ExampleCanvas glbComponentName='Forest6' />
        <ExampleCanvas glbComponentName='Sakura4' />
        <ExampleCanvas glbComponentName='Forest4' />
        <ExampleCanvas glbComponentName='Sakura3' />
        <ExampleCanvas glbComponentName='Forest5' />         
    </div>


    
    <div className='info-page-container'>
    <FadeInWhenVisible>              
    <h1 style={{margin: '3em 0 1em 0'}} className = 'getaways-header'>GETAWAYS</h1>               
    </FadeInWhenVisible> 
    <div className = "poem-container infopage-quote">

    <FadeInWhenVisible>
    <p>400 unique islands will come to life, </p>
    </FadeInWhenVisible>
    <FadeInWhenVisible>
    <p>another biome to explore,</p>
    </FadeInWhenVisible>
    <FadeInWhenVisible>
    <p>individual and communal voyages,</p>
    </FadeInWhenVisible>
    <FadeInWhenVisible>
    <p>more to see than meets the eye.</p>
    </FadeInWhenVisible>
    
    
    
                   
    
    
    
    
    </div>
    <FadeInWhenVisible>
     
    <h2 style={{paddingTop: '2.5em 0', textAlign: 'center'}}>POLICY ID: <br/> <p className='policy-ID' style={{backgroundColor:'whitesmoke', color: 'black' , padding: '0.4em 0.6em', fontSize: '0.7em', margin: '0, 5%'}}>T.B.A.</p></h2>
        <h3 style={{paddingTop: '2em'}}>ISLANDS INHABITED: 0/400</h3>
    <a style={{color:'black'}} href="https://pool.pm/c9fa6fea1d489e16732967528e3c1c8a0121d9a570b92e768030383a.GTWPOCS1" target="_blank"><p>proof of concept</p></a>
    </FadeInWhenVisible>
    <FadeInWhenVisible>
    <button onClick={startVoyage} class = 'button'>{button}</button>
    </FadeInWhenVisible>
    </div>

    
    

    </AnimatePresence>
    )
}
if(page === 'faq'){
    return(
        <>
        <img className='cardano-logo' style={{height: '1.2em', width: 'auto', margin: '0 0.1em', position: 'fixed', bottom: '2em', right: '2em'}} src="/cardano-logo.png" alt="" />
        <div className="header-icons-container">
        <h2 className="nav-link"  onClick={()=> setPage('home')} style={{marginRight: '1em'}}>Islands</h2>
        <h2 className="nav-link" onClick={()=> setPage('creatures')}>Wildlife</h2>
        <h2 className="nav-link" onClick={()=> setPage('artifacts')} style={{marginLeft: '1em'}}>Artifacts</h2>
        <h2 className="nav-link" onClick={()=> setPage('faq')} style={{marginLeft: '1em'}}>FAQ</h2>
        <a href='https://discord.gg/mQ2KAVNU9v' target = '_blank' >
            <DiscordIcon className = 'footer-image discord-logo'/>
        </a>
        <a href = 'https://twitter.com/teamgetaways' target = '_blank'>
            <TwitterIcon className = 'footer-image twitter-logo' />
        </a>
        </div>
        <div className='faq-container'>
            <h2> 1. What is Getaways?</h2>
            <p className="faq-p">Getaways is a Cardano based NFT series that features individually crafted 3D islands. These islands can be viewed from all sides in your browser. Some islands 
will hold wildlife, like our friendly beetle. With some islands you will be able to interact. Some islands will have mysterious symbols, hidden objects and interactions that 
can be necessary for completing voyages (see 3. What are voyages?).</p>
            <h2>2. Where can I view these islands?</h2>
            <p className="faq-p">For now you can take a look at the proof of concept,
which will be burned on release.

 
 On release, all islands can be viewed on https://pool.pm/ by searching for your wallet containing the NFT. 
 <a style={{color:'black'}} href="https://pool.pm/c9fa6fea1d489e16732967528e3c1c8a0121d9a570b92e768030383a.GTWPOCS1" target="_blank"><p style={{textAlign: 'left'}}>proof of concept</p></a>
</p>
            <h2>3. What are voyages? </h2>
            <p className="faq-p">Voyages are online puzzle events that upon completion give individuals or the community certain benefits and or rewards. Think of them as escape room or 
scavenge hunt type puzzles. You will need to find things to interact with, solve puzzles and possibly use external sources to find the solutions. Voyages start on a designated discord channel for that specific voyage. </p>
            <h3>3.1 Individual voyages</h3>
            <p className="faq-p">Individual voyages are voyages that a member embarks on on their own. Individual voyages will be played exclusively on this website. 
       When a voyage event starts, the website will update to contain a series of puzzles and mysteries that you have to progress through. 

       To prevent the spread of hints and/or solutions, all chats will be blocked during such events. This can take anywhere between an hour to a day, depending on the 
       difficulty of the voyage. Rewards can be anything from white-listing to airdrops and more. </p>
            <h3>3.2 Communal voyages</h3>
            <p className="faq-p">  Communal voyages are voyages where the entire community have to work together to obtain the solution. These voyages will stretch over a longer time 
       frame and are significantly more difficult then individual voyages. Parts of these voyages will be played on this website, but you will also need to 
       explore Getaways islands owned by members of the community. Some islands will show symbols, some will hold clues hidden behind small puzzles, some might never be 
       discovered...</p>
       <h2>4.When will the first voyage be?</h2>
            <p className="faq-p">The first voyage will be an individual voyage held soon after the beetles have found a home. The first 100 to complete it will be white listed for Getaways Islands season 1. 
The first 50 will get to place a beetle they own on an island when minting!
<br/><br/>
The first communal voyage will start somewhere after the first series of Getaway islands have been released. Feel free to explore and discuss whatever you can find in the meanwhile. More information on communal voyages will follow in the future.
</p>
            <h2>5. When/Where mint?</h2>
            <p className="faq-p">Minting of Getways Islands Season 1 will start as soon mother nature is done forming places to Getaway. If you want to support the project, a pre-release animated 
collectable will be available before the launch of Getaways Islands (see 6. What's up with the beetles?).
The minting address will appear on our website and only on our website!</p>
            
            <h2>6. What's up with the beetles?</h2>
            <p className="faq-p">The proud and friendly beetle is the first wildlife to arrive on Getaways Islands. A total of 400 beetles of in 9 different color combinations will land, some rarer then others. Beetles will be available for 27 ada. 10% of all profits will go to the Coral Restoration Foundation.
            <a style={{color:'black'}} href="https://pool.pm/ca17b2030a89d9c23a146e4cbfbb0e85c66b6a53d443b96be26fcae0.GTWPOCBT" target="_blank"><p style={{textAlign: 'left'}}>proof of concept</p></a> 
            </p>
           
        </div>
        </>
    )
}
if(page === 'creatures'){ return(
    <>  
        <img className='cardano-logo' style={{height: '1.2em', width: 'auto', margin: '0 0.1em', position: 'fixed', bottom: '2em', right: '2em'}} src="/cardano-logo.png" alt="" />
        <div className="header-icons-container">
        <h2 className="nav-link"  onClick={()=> setPage('home')} style={{marginRight: '1em'}}>Islands</h2>
        <h2 className="nav-link" onClick={()=> setPage('creatures')}>Wildlife</h2>
        <h2 className="nav-link" onClick={()=> setPage('artifacts')} style={{marginLeft: '1em'}}>Artifacts</h2>
        <h2 className="nav-link" onClick={()=> setPage('faq')} style={{marginLeft: '1em'}}>FAQ</h2>
        <a href='https://discord.gg/mQ2KAVNU9v' target = '_blank' >
            <DiscordIcon className = 'footer-image discord-logo'/>
        </a>
        <a href = 'https://twitter.com/teamgetaways' target = '_blank'>
            <TwitterIcon className = 'footer-image twitter-logo' />
        </a>
        </div>
        
        <div className = "poem-container" style = {{padding: '10em 0'}}>
        
                <p>Some beetles have already found their place,</p>
            
                <p>I'm sure there will be more.</p>
     
                <p>If you catch one do so with care,</p>
              
                <p>you might just share a home.</p>
              
            </div>
        
       
        <div className = "examples-grid-container-beetles">
            
            
        <ExampleBeetle glbComponentName='LogPusher' />  
        <ExampleBeetle glbComponentName='ForestDefender' />   
        <ExampleBeetle glbComponentName='PetalFolder' />   
        <ExampleBeetle glbComponentName='CloudHopper' />   
        <ExampleBeetle glbComponentName='DesertCrawler' />   
        <ExampleBeetle glbComponentName='LightfootDaybreaker' />   
        <ExampleBeetle glbComponentName='CherryNibbler' />   
        
        <ExampleBeetle glbComponentName='DarkBeetle' />   
        <ExampleBeetle glbComponentName='DarkBeetle' />   
       
             
           
        </div>
        
        <div className='poem-container' style={{paddingBottom: '7em'}}>
       
        <h2 style={{paddingTop: '2.5em 0', textAlign: 'center'}}>POLICY ID: <br/> <p className='policy-ID' style={{backgroundColor:'whitesmoke', color: 'black' , padding: '0.4em 0.6em', fontSize: '0.7em', margin: '0, 5%'}}>d3a9bc224420545a94db518165fd651694de0ebab4e873ed142a1484</p></h2>
        <h3>BEETLES LANDED: 11/400</h3>
         <a style={{color:'black'}} href="https://pool.pm/ca17b2030a89d9c23a146e4cbfbb0e85c66b6a53d443b96be26fcae0.GTWPOCBT" target="_blank"><p>proof of concept</p></a>
       
         </div>
         </>
)}
else return(
<>  
<img className='cardano-logo' style={{height: '1.2em', width: 'auto', margin: '0 0.1em', position: 'fixed', bottom: '2em', right: '2em'}} src="/cardano-logo.png" alt="" />
<div className="header-icons-container">
<h2 className="nav-link"  onClick={()=> setPage('home')} style={{marginRight: '1em'}}>Islands</h2>
<h2 className="nav-link" onClick={()=> setPage('creatures')}>Wildlife</h2>
<h2 className="nav-link" onClick={()=> setPage('artifacts')} style={{marginLeft: '1em'}}>Artifacts</h2>
<h2 className="nav-link" onClick={()=> setPage('faq')} style={{marginLeft: '1em'}}>FAQ</h2>
<a href='https://discord.gg/mQ2KAVNU9v' target = '_blank' >
    <DiscordIcon className = 'footer-image discord-logo'/>
</a>
<a href = 'https://twitter.com/teamgetaways' target = '_blank'>
    <TwitterIcon className = 'footer-image twitter-logo' />
</a>
</div>
<div className = "poem-container" style = {{padding: '10em 0'}}>
      
                <p>A strange energy,</p>
        
                <p>locked by something else...</p>
            
                <p>If only I knew,</p>
        
                <p>gold would fall. </p>
                  
    </div>
    <ExampleCanvas glbComponentName='PuzzleDark' />
    <div style={{marginBottom: '15em'}}></div>
    <div className='poem-container' style={{paddingBottom: '7em'}}>
       
        <h2 style={{paddingTop: '2.5em 0', textAlign: 'center'}}>POLICY ID: <br/> <p className='policy-ID' style={{backgroundColor:'whitesmoke', color: 'black' , padding: '0.4em 0.6em', fontSize: '0.7em', margin: '0, 5%'}}>T.B.A.</p></h2>
        <h3>ARTIFACTS OPENED: 0/100</h3>
      
       
         </div>
    </>
   )
}