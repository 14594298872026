/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/desert5.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Plane091" rotation={[2.91, -0.69, 2.86]} scale={[1.44, 1.44, 1.44]}>
        <mesh geometry={nodes.Plane082.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Plane082_1.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Plane082_2.geometry} material={materials['dry grass 1']} />
        <mesh geometry={nodes.Plane082_3.geometry} material={materials['Material.040']} />
      </group>
    </group>
  )
}

useGLTF.preload('/desert5.glb')
