import {Suspense} from 'react'
import {Canvas} from '@react-three/fiber'
import {CameraShake, OrbitControls, Sky, Stars} from '@react-three/drei'
import FadeInWhenVisible from './FadeInWhenVisible.js'
import CustomLoader from './CustomLoader.js'
import Desert1 from '../GLB/Desert1.js'
import Desert2 from '../GLB/Desert2.js'
import Desert3 from '../GLB/Desert3.js'
import Desert4 from '../GLB/Desert4.js'
import Desert5 from '../GLB/Desert5.js'
import Forest3 from '../GLB/Forest3.js'
import Forest4 from '../GLB/Forest4.js'
import Forest5 from '../GLB/Forest5.js'
import Forest6 from '../GLB/Forest6.js'
import Sakura3 from '../GLB/Sakura3.js'
import Sakura4 from '../GLB/Sakura4.js'
import PuzzleDark from '../GLB/Puzzledark.js'

import './ExampleCanvas.css'

export default function ExampleCanvas(props){


   
   
    const Components = {
    Desert1,
    Desert2,
    Desert3,
    Desert4,
    Desert5,
    Forest3,
    Forest4,
    Forest5,
    Forest6,
    Sakura3,
    Sakura4,
    PuzzleDark,
   
  }

  function Glb(){

    let GLBComponent = Components[props.glbComponentName]

    if(!props.glbComponentName){
      return null
    }
    else return(
      <GLBComponent/> 
    )
  }


    return(
     
      <FadeInWhenVisible>
        <div className = "canvas-example-container">
   
            <Canvas
                style={{ height: '100%', width: '100%'}}
                camera = {{
                position: [10, 0, 29],
                fov: 30
                }}
            >
                <Suspense fallback = {CustomLoader}>
                <OrbitControls enableZoom={false} enablePan={false}/>
                <Sky azimuth={0.5} inclination={5} />
                <Glb className='example-glb'/>
                </Suspense>
            </Canvas>
            <img className = 'getaways-example-header' src = "/getaways-logo.png" alt=''/>

        </div>
        </FadeInWhenVisible>

      )
}