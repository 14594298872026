/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/desert6.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Cube064" rotation={[-0.94, -0.62, -0.66]} scale={[1.98, 0.99, 1.49]}>
        <mesh geometry={nodes.Cube064_1.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Cube064_2.geometry} material={materials['Material.038']} />
        <mesh  geometry={nodes.Cube064_3.geometry} material={materials['dry grass 1']} />
        <mesh  geometry={nodes.Cube064_4.geometry} material={materials['Material.045']} />
        <mesh geometry={nodes.Cube064_5.geometry} material={materials['Material.001']} />
      </group>
    </group>
  )
}

useGLTF.preload('/desert6.glb')
