
import './App.css';
import Home from './Components/Main/Home/Home.js';
import Header from './Components/Main/Header/Header.js';
import Footer from './Components/Main/Footer/Footer.js';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  return (
    <ParallaxProvider>
    <div className="App">
      <div className = 'page-container'>
        <Header/>
        <Home/>
        <Footer/>
      </div>
    </div>
    </ParallaxProvider>
  );
}

export default App;
