/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/puzzledark.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[0, Math.PI / 2, 0]} scale={1}>
        <mesh geometry={nodes.Cube017.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cube017_1.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Cube017_2.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Cube017_3.geometry} material={materials['Material.004']} />
        <mesh geometry={nodes.Cube017_4.geometry} material={materials['Material.001']} />
      </group>
    </group>
  )
}

useGLTF.preload('/puzzledark.glb')
