/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/desert4.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Cube051" rotation={[-0.66, -0.55, -0.09]} scale={[1.84, 0.92, 1.38]}>
        <mesh geometry={nodes.Cube014.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Cube014_1.geometry} material={materials['Material.039']} />
      </group>
    </group>
  )
}

useGLTF.preload('/desert4.glb')
