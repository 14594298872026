import './Header.css';
import '../../../index.css';
import { motion, AnimatePresence } from "framer-motion"
import React, { Component } from 'react';

    class Header extends Component {
      state = {
        display: '',
      }

      
    
      componentDidMount() {
        const vw = window.innerWidth
        console.log(vw)
        if (typeof window !== "undefined") {
          window.onscroll = () => {
            let currentScrollPos = window.pageYOffset;
            
      
            if (currentScrollPos > 680 && vw > 800) {
              this.setState({ display: "none" })
              
            } else {
              this.setState({ display: "" })
            }
          }
        }
      }
    
      render() {




        return (
           

<AnimatePresence>
<motion.div 
    
    className = 'header-container'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{
        duration: 4
    }}
>
    <h1 style={{ display: `${this.state.display}`}} className = 'getaways-header'>GETAWAYS</h1>
</motion.div>
</AnimatePresence>
        )
      }
    }
    
    export default Header