/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import {useFrame} from '@react-three/fiber'

export default function Model({ ...props }) {
  const group = useRef()
  const group1 = useRef()
  const group2 = useRef()
  const group3 = useRef()
  const group4 = useRef()
  const { nodes, materials, animations } = useGLTF('/puzzlecounter.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> {
    actions.float1.play()
    actions.float2.play()
    actions.float3.play()
    actions.float4.play()
  });
  useFrame(() => {
    group1.current.rotation.y = group1.current.rotation.y += 0.01
    group2.current.rotation.y = group2.current.rotation.y += 0.01
    group3.current.rotation.y = group3.current.rotation.y += 0.01
    group4.current.rotation.y = group4.current.rotation.y += 0.01
  
  
  });
  return (
    <group ref={group} {...props} dispose={null} position={[-0.25,0.72,0.36]} rotation={[-0.06,0.24,0]}>
      <group ref={group2} name="2" position={[0, 0, -0.01]} rotation={[1.57, -1.57, 0]} scale={[0.25, 0.29, 0.25]}>
        <mesh geometry={nodes.Circle.geometry} material={nodes.Circle.material} />
        <mesh geometry={nodes.Circle_1.geometry} material={nodes.Circle_1.material} />
      </group>
      <group ref={group4} name="4" position={[0, 0, -0.01]} rotation={[1.57, -2, 0]} scale={0.76}>
        <mesh geometry={nodes.Circle002.geometry} material={nodes.Circle002.material} />
        <mesh geometry={nodes.Circle002_1.geometry} material={nodes.Circle002_1.material} />
      </group>
      <group ref={group3} name="3" position={[0, 0, -0.01]} rotation={[-1.57, 1, -Math.PI]} scale={[0.15, 0.15, 0.15]}>
        <mesh geometry={nodes.Text025.geometry} material={nodes.Text025.material} />
        <mesh geometry={nodes.Text025_1.geometry} material={nodes.Text025_1.material} />
      </group>
      <group ref={group1} name="1" position={[0, 0, -0.01]} rotation={[1.57, 3, 0]} scale={[0.13, 0.21, 0.13]}>
        <mesh geometry={nodes.Circle004.geometry} material={nodes.Circle004.material} />
        <mesh geometry={nodes.Circle004_1.geometry} material={nodes.Circle004_1.material} />
      </group>
    </group>
  )
}

useGLTF.preload('/puzzlecounter.glb')
