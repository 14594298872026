/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/sakura1.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sphere120" position={[0.01, 0.02, 0.23]} rotation={[-3.08, 0.46, -3.12]} scale={[2.44, 0.87, 2.44]}>
        <mesh geometry={nodes.Sphere120_1.geometry} material={materials.jpbase} />
        <mesh geometry={nodes.Sphere120_2.geometry} material={materials.Material} />
        <mesh geometry={nodes.Sphere120_3.geometry} material={materials.steen} />
        <mesh geometry={nodes.Sphere120_4.geometry} material={materials['mos jp']} />
        <mesh geometry={nodes.Sphere120_5.geometry} material={materials['Material.004']} />
        <mesh geometry={nodes.Sphere120_6.geometry} material={materials['Material.001']} />
      </group>
    </group>
  )
}

useGLTF.preload('/sakura1.glb')
