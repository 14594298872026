/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/sakura3.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}  position={[0,-1,0]}>
      <group name="Sphere039" rotation={[-0.65, 1.02, 0.87]} scale={[0.26, 0.12, 0.26]}>
        <mesh geometry={nodes.Sphere047.geometry} material={materials.cherrydark} />
        <mesh geometry={nodes.Sphere047_1.geometry} material={materials.steen} />
        <mesh geometry={nodes.Sphere047_2.geometry} material={materials.jpbase} />
        <mesh geometry={nodes.Sphere047_3.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Sphere047_4.geometry} material={materials['Material.047']} />
      </group>
    </group>
  )
}

useGLTF.preload('/sakura3.glb')
