/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/sakura4.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sphere033" rotation={[0.49, 0.04, 1.1]} scale={[0.46, 0.46, 0.46]}>
        <mesh geometry={nodes.Sphere062.geometry} material={materials.steen} />
        <mesh geometry={nodes.Sphere062_1.geometry} material={materials.jpbase} />
      </group>
    </group>
  )
}

useGLTF.preload('/sakura4.glb')
