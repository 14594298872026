/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/sakura2.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name="Cylinder073"
        position={[-0.01, 0.02, 0.01]}
        rotation={[-2.26, 1.37, -0.25]}
        scale={[0.19, 0.2, 0.19]}>
        <mesh geometry={nodes.Cylinder083.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Cylinder083_1.geometry} material={materials.jpbase} />
        <mesh geometry={nodes.Cylinder083_2.geometry} material={materials.cherrydark} />
      </group>
    </group>
  )
}

useGLTF.preload('/sakura2.glb')
