/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import {useFrame} from '@react-three/fiber'
import { useSpring, animated, config } from '@react-spring/three'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/desertstone1.glb')
  const { actions } = useAnimations(animations, group)
 
  const [active,setActive] = useState(true)
  


  
  
  const { location } = useSpring({
    location: active ? [0,4,0] : [0,-2,0] ,
    config: config.molasses
    
  });
  const myMesh = React.useRef();
  useFrame(() => (myMesh.current.rotation.y = myMesh.current.rotation.y += 0.01));
  
  return (
    <group ref={myMesh} {...props} dispose={null} scale={0.7} position={[0,-0.4,0]} >
      <animated.mesh
        name="Cube057"
        geometry={nodes.Cube057.geometry}
        material={materials['Material.002']}
        position={location}
        rotation={[1.11, 0.97, -2.78]}
        scale={[2.15, 0.99, 1.79]}
      />
    </group>
  )
}

useGLTF.preload('/desertstone1.glb')
