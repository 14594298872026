import './Footer.css';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as DiscordIcon } from '../../NonPublicImages/discord-logo.svg';
import FadeInWhenVisible from '../../Secondary/Functional/FadeInWhenVisible';

export default function Footer(){
    return(
    <div className = 'layout footer-container'>
        <a href='https://discord.gg/mQ2KAVNU9v' target = '_blank' >
        <DiscordIcon className = 'footer-image discord-logo'/>
        </a>
        <a href = 'https://twitter.com/teamgetaways' target = '_blank'>
            <TwitterIcon className = 'footer-image twitter-logo' />
        </a>
        <FadeInWhenVisible>
        <div className = "text-balloon-container">
                <img className = "text-balloon" src="/text-balloon.png" alt="" />
                <p className = "text-balloon-text">Did you spot the beetle?</p>
        </div>
        </FadeInWhenVisible>
    </div>);
}