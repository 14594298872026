/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'


export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/forest5.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null}  position={[0,-1.3,0]}>
      <group name="Sphere014" rotation={[-0.08, 0, 0]} scale={[2.27, 0.99, 2.27]}>
        <mesh geometry={nodes.Sphere025.geometry} material={materials.forestground} />
        <mesh geometry={nodes.Sphere025_1.geometry} material={materials.stoneforest} />
        <mesh geometry={nodes.Sphere025_2.geometry} material={materials.mossforest} />
        <mesh geometry={nodes.Sphere025_3.geometry} material={materials.steen} />
        <mesh geometry={nodes.Sphere025_4.geometry} material={materials['Material.009']} />
      </group>
    </group>
  )
}

useGLTF.preload('/forest5.glb')
