/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useSpring, animated, config } from '@react-spring/three'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/forest1.glb')
  const { actions } = useAnimations(animations, group)
  const [active,setActive] = useState(false)
    
  const myMesh = React.useRef();
  useEffect(()=> actions.float.play());
  return (
    <group ref={group} {...props} dispose={null} >
      <group name="Sphere029" rotation={[-3.1, -0.62, -3.04]} scale={[0.89, 0.29, 0.87]}  >
        <mesh  geometry={nodes.Sphere018.geometry} material={materials.stoneforest}  />
        <mesh geometry={nodes.Sphere018_1.geometry} material={materials.mossforest}  />
        <mesh geometry={nodes.Sphere018_2.geometry} material={materials.steen} />
        <mesh geometry={nodes.Sphere018_3.geometry} material={materials['Material.009']} />
        <mesh geometry={nodes.Sphere018_4.geometry} material={materials['Material.027']} />
        {/* <mesh geometry={nodes.Sphere018_5.geometry} material={materials['Material.015']} /> */}
        <mesh o geometry={nodes.Sphere018_6.geometry} material={materials.forestground} onClick={function(){
          setActive(true)
          console.log('clicked')
          
          }} ref={myMesh}/>
      </group>
    </group>
  )
}

useGLTF.preload('/forest1.glb')
