import {Suspense} from 'react'
import {Canvas} from '@react-three/fiber'
import {OrbitControls} from '@react-three/drei'
import CustomLoader from './CustomLoader.js'
import FadeInWhenVisible from './FadeInWhenVisible.js'
import LogPusher from '../GLB/LogPusher.js'
import CloudHopper from '../GLB/CloudHopper.js'
import DesertCrawler from '../GLB/DesertCrawler.js'
import LightfootDaybreaker from '../GLB/LightfootDaybreaker.js'
import PetalFolder from '../GLB/PetalFolder.js'
import DarkBeetle from '../GLB/DarkBeetle.js'
import CherryNibbler from '../GLB/CherryNibbler.js'
import ForestDefender from '../GLB/ForestDefender.js'

import './ExampleCanvas.css'

export default function ExampleCanvas(props){


   
   
    const Components = {
    LogPusher,
    DesertCrawler,
    CloudHopper,
    LightfootDaybreaker,
    DarkBeetle,
    PetalFolder,
    CherryNibbler,
    ForestDefender
   
  }



  let GLBComponent = Components[props.glbComponentName]

    return(
         <FadeInWhenVisible>
    
        <div className = "canvas-example-container">
       
            <Canvas
                style={{ height: '100%', width: '100%'}}
                camera = {{
                position: [10, 15, 29],
                fov: 25
                }}
                shadows
                
            >
              
              <Suspense fallback = {<CustomLoader color='white'/>}>
                <OrbitControls enableZoom={false} enablePan={false}/>
                
                <GLBComponent className='example-glb'/>
                </Suspense>
            </Canvas>
          
            <img className = 'getaways-example-header' src = "/getaways-logo.png" alt=''/>
           
        </div>
        </FadeInWhenVisible>

      )
}